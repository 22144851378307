import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = false
import VueRouter from 'vue-router'
import router from './router'

import "swiper/swiper-bundle.min.css";
/************************ */
Vue.use(VueRouter)
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
document.title='力量联盟--言文科技'