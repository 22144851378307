<template>
  <div class="wrapper">
    <div class="headBox">
      <div class="content">
        <div class="head">
          <div class="logo">
            <img src="../assets/logo.png" alt="" />力量联盟
          </div>
          <div class="nav">
            <span @click="toIndex()">首页</span>
            <span><a @click="back('#ability')">体能赛</a></span
            ><span><a @click="back('#power')">力量榜</a></span
            ><span><a @click="back('#skill')">达人秀</a></span
            ><span><a @click="back('#practive')">训练打卡</a></span
            ><span><a>关于我们</a></span>
          </div>
        </div>
        <div class="headContent">
          <div class="swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <p>全民体能赛</p>
                <div class="word">
                  <p>没有时空限制的体能竞技平台</p>
                  <p>赛事内容全环节订制</p>
                  <p>赛事运营全流程闭环管理</p>
                </div>
              </div>
              <div class="swiper-slide">
                <p>全民力量榜</p>
                <div class="word">
                  <p>各种体能项目的竞技排行</p>
                  <p>记录全网力量之最</p>
                  <p>更多圈子排名激发训练热情</p>
                </div>
              </div>
              <div class="swiper-slide">
                <p>达人秀</p>
                <div class="word">
                  <p>各种酷炫的体能秀</p>
                  <p>传说中的街健神技</p>
                  <p>训练的是身体，放飞的是灵魂</p>
                </div>
              </div>
              <div class="swiper-slide">
                <p>训练打卡</p>
                <div class="word">
                  <p>以明确的数量目标为导向</p>
                  <p>每日“完形填空”式任务驱动</p>
                  <p>培养健身好习惯</p>
                </div>
              </div>
            </div>
          </div>
          <div class="codeBox">
            <div class="item">
              <img src="../assets/code1.png" alt="" />
              <p>公众号</p>
            </div>
            <div class="item">
              <img src="../assets/code3.png" alt="" />
              <p>小程序</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="our">
      <div class="content">
        <div class="menu">
          <div>力量联盟</div>
          <div>赛事运营</div>
          <div>赛事赞助</div>
          <div>联系我们</div>
        </div>
        <div class="body">
          <div class="block">
            <p>力量联盟</p>
            <div class="word">
              <div>力量联盟以推动全民健身运动为核心宗旨，通过对体能训练项目赛事化运作，打造亲民便利、没有门槛的群众性竞技舞台。借助互联网信息科技的应用，打破传统体育竞技项目对时间、空间的约束，实现居家竞赛，任意时间、任何场地的自由参赛。利用“群众性、趣味性、可参与性高”的特点推动全民健身热潮。</div>
              <div>力量联盟从多个方面深度挖掘体能项目的趣味性和参与性，通过“力量榜”栏目构建全民体能排行榜，记录体能之最，打造中国版体能项目的“吉尼斯记录”。更通过多种排名圈激发竞技本能。同时，对接健身达人，通过健身达人的酷炫技能带动更多人的健身热情。</div>
              <div>力量联盟推动全民健身的核心思路是，“让你心动，帮你行动”，拥有完整的服务闭环。体能赛、力量榜、达人秀可以激发你的健身热情。训练打卡则是辅助你养成健身习惯的好助手，训练项目、执行计划、可量化的目标确定后，每日以“完形填空”式任务驱动着我们完成作业。</div>
            </div>
          </div>
          <div class="block">
            <p>赛事运营</p>
            <div class="word">
              力量联盟的赛事运营服务是一个完全开放的服务体系，并具备全环节定制能力，任何团体个人都可以在平台上发布赛事，并做到全流程的运营管理。体育运营机构、校园、新媒体达人等各类实体都可以链接赞助商发布赛事，实现多方共赢。
              平台欢迎各类赛事运营的专属合作，为不同赛事提供个性化支撑。
            </div>
          </div>
          <div class="block">
            <p>赛事赞助</p>
            <div class="word">
              平台欢迎一切有广告需求的商家洽谈合作，可为其定制冠名赛事，可根据商家的业务范围制定区域性或全国范围的赛事，根据赞助力度制定不同的推广等级。
            </div>
          </div>
          <div class="block">
            <p>联系我们</p>
            <div class="word">
              <p>电话：13072521981</p>

              <p>微信：jiujiangyanwen</p>

              <p>邮箱：yanwenxinxi@163.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <a href="https://beian.miit.gov.cn/">九江言文信息科技有限公司 Copyright 2019-2022  赣ICP备19004340号-1</a>
      <div class="codeBox">
        <div class="item">
          <img src="../assets/code1.png" alt="" />
          <p>公众号</p>
        </div>
        <div class="item">
          <img src="../assets/code3.png" alt="" />
          <p>小程序</p>
        </div>
        <div class="item">
          <img src="../assets/code2.png" alt="" />
          <p>商务合作</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Autoplay } from "swiper";
Swiper.use([Autoplay]);
export default {
  data() {
    return {};
  },
  methods: {
    back(selector) {
        this.$router.push({
          name:'index'
        })
        localStorage.setItem('modules', selector)
    },
    toIndex(){
      this.$router.push({
        path:'/index'
      })
      localStorage.removeItem('modules')
    }
  },
  created(){
    
  },
  mounted() {
    this.$nextTick(function () {
      new Swiper(".swiper", {
        loop: true,
        autoplay: {
          delay:5000
        },
      });
    });
  },
};
</script>

<style lang='scss' scoped>
@import "index.scss";
</style>