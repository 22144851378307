import Vue from 'vue' //引入Vue

import Router from 'vue-router' //引入vue-router

import index from '@/view/index'
import ours from '@/view/ours'

Vue.use(Router) //Vue全局使用Router

export default new Router({

    routes: [
        {
            path: '/',
            redirect: '/index'
        },
        {
            path: '/index',            
            name: 'index',
            component: index
        },
        {
            path: '/ours',            
            name: 'ours',
            component: ours
        }
        
    ]

})