<template>
  <div class="wrapper">
    <div class="headBox">
      <div class="content">
        <div class="head">
          <div class="logo">
            <img src="../assets/logo.png" alt="" />力量联盟
          </div>
          <div class="nav">
            <span>首页</span><span><a @click="to('#ability')">体能赛</a></span
            ><span><a @click="to('#power')">力量榜</a></span
            ><span><a @click="to('#skill')">达人秀</a></span
            ><span><a @click="to('#practive')">训练打卡</a></span
            ><span><a @click="toOur()">关于我们</a></span>
          </div>
        </div>
        <div class="headContent">
          <div class="swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <p>全民体能赛</p>
                <div class="word">
                  <p>没有时空限制的体能竞技平台</p>
                  <p>赛事内容全环节订制</p>
                  <p>赛事运营全流程闭环管理</p>
                </div>
              </div>
              <div class="swiper-slide">
                <p>全民力量榜</p>
                <div class="word">
                  <p>各种体能项目的竞技排行</p>
                  <p>记录全网力量之最</p>
                  <p>更多圈子排名激发训练热情</p>
                </div>
              </div>
              <div class="swiper-slide">
                <p>达人秀</p>
                <div class="word">
                  <p>各种酷炫的体能秀</p>
                  <p>传说中的街健神技</p>
                  <p>训练的是身体，放飞的是灵魂</p>
                </div>
              </div>
              <div class="swiper-slide">
                <p>训练打卡</p>
                <div class="word">
                  <p>以明确的数量目标为导向</p>
                  <p>每日“完形填空”式任务驱动</p>
                  <p>培养健身好习惯</p>
                </div>
              </div>
            </div>
          </div>
          <div class="codeBox">
            <div class="item">
              <img src="../assets/code1.png" alt="" />
              <p>公众号</p>
            </div>
            <div class="item">
              <img src="../assets/code3.png" alt="" />
              <p>小程序</p>
            </div>
          </div>
        </div>
        <div id="ability"></div>
      </div>
    </div>
    <div class="system">
      <div class="content">
        <p>完整的赛事运营体系</p>
        <div class="word">
          从创建赛事到赛事结束的资金结算，全流程闭环管理。赛事内容灵活定制，包括比赛项目、奖<br />
          品、推广、赞助广告、报名费等全环节个性化配置。
        </div>
        <div class="note">
          各种团体个人赛事运营的自助平台，更能为大规模赛事提供个性化解决方案。
        </div>
        <img src="../assets/system.png" alt="" />
      </div>
    </div>
    <div class="power" id="power">
      <div class="content">
        <p>全民力量榜</p>
        <div class="word">
          俯卧撑、引体向上、平板支撑等各种体能项目的竞技排行，记录体能之最。区域、性别、年龄<br />
          段等各种圈子排名激发训练热情。
        </div>
        <div class="imgBox">
          <img src="../assets/power1.png" alt="" />
          <img src="../assets/power2.png" alt="" />
          <img src="../assets/power3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="skill" id="skill">
      <div class="content">
        <p>达人秀</p>
        <div class="word">
          各种健身达人的酷炫技能秀，力量与美的融合，让我们知道基础的体能训练也能有艺术般的表<br />
          现，身体的自由带动灵魂的放飞。
        </div>
        <div class="itemBox">
          <img src="../assets/skill1.jpg" alt="" class="item" /><img
            src="../assets/skill2.png"
            alt=""
            class="item"
          /><img src="../assets/skill3.jpg" alt="" class="item" /><img
            src="../assets/skill4.jpeg"
            alt=""
            class="item"
          /><img src="../assets/skill5.jpeg" alt="" class="item" /><img
            src="../assets/skill6.jpg"
            alt=""
            class="item"
          />
        </div>
      </div>
    </div>
    <div class="practive" id="practive">
      <div class="content">
        <p>训练打卡</p>
        <div class="word">
          结合自身特点选择不同的健身项目，制定训练计划，以精确的数量目标为引导，每日“完形填<br />
          空”式任务驱动，更有详尽的打卡数据自我激励，培养健身习惯。
        </div>
        <div class="imgBox">
          <img src="../assets/practive1.png" alt="" />
          <img src="../assets/practive2.png" alt="" />
          <img src="../assets/practive3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="footer">
      <a href="https://beian.miit.gov.cn/">九江言文信息科技有限公司 Copyright 2019-2022  赣ICP备19004340号-1</a>
      <div class="codeBox">
        <div class="item">
          <img src="../assets/code1.png" alt="" />
          <p>公众号</p>
        </div>
        <div class="item">
          <img src="../assets/code3.png" alt="" />
          <p>小程序</p>
        </div>
        <div class="item">
          <img src="../assets/code2.png" alt="" />
          <p>商务合作</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Autoplay } from "swiper";
Swiper.use([Autoplay]);
export default {
  data() {
    return {};
  },
  methods: {
    to(selector) {
        document.querySelector(selector).scrollIntoView({
          behavior: "smooth",
        });
    },
    toOur() {
      this.$router.push({
        path: "/ours",
      });
    },
  },
  mounted() {
    
    if(localStorage.getItem("modules")){
      this.to(localStorage.getItem("modules"))
    }
    new Swiper(".swiper", {
      loop: true,
      autoplay: {
        delay: 5000,
      },
    });
  },
};
</script>

<style lang='scss' scoped>
@import "index.scss";
</style>